.nav-hero {
    width: 100vw;
    position: relative;
}

.navbar-links {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: var(--background-color);
    /* background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); */

}

li {
    list-style: none;
}

li a {
    text-decoration: none;
    color: var(--text);
}

.logo img {
    width: 150px;
    height: 150px;
}

.logo-left {
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    display: none;
}

.toggle-btn {
    width: 80px;
    border: none;
    padding: 5px;
    border-radius: 50px;
    background-color: var(--background-color);
    cursor: pointer;
    color: var(--text);
}

.hamburger-ctr {
    display: none;
    cursor: pointer;
}

.hamburger-icon {
    font-size: 1.8rem;
    top: 20px;
    right: 30px;
    position: fixed;
    z-index: 300;
}

.mobile-links {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 7rem;
    gap: 20px;
    font-size: 1rem;
    background-color: var(--background-color);
    letter-spacing: 1px;
    height: 100vh;
    width: 35vw;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 100;
    transform: translateX(105%);
    transition: transform 0.8s ease-in-out;
    overflow: hidden;
}

.mobile-link a {
    color: var(--text);
    font-weight: 400;
    text-decoration: none;
}

.mobile-link a:hover {
    color: var(--text);
}

.open {
    transform: translateY(0%);
}

.toggle-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    margin: 0;
    color: var(--bright-main);
    font-size: 1.8rem;
}

.toggle-button:hover {
    color: var(--dark-main);
}

@media only screen and (max-width: 800px) {
    .navbar-links {
        display: none;
    }

    .logo-left {
        display: block;
    }

    .hamburger-ctr {
        display: block;

    }

    .hamburger-icon {
        background-color: var(--background-color);
        padding: 10px;
        border-radius: 50%;
    }
}