.container-audio {
    margin: 20% 10%;
    border: 2px solid var(--text);
    border-radius: 30px;
    padding: 30px;
}

/* audio info */
.audio-info {
    display: flex;
    gap: 20px;
    color: var(--text);
}

.text {
    color: var(--accent);
}

.title {
    color: var(--accent);
    font-weight: 100;
    font-size: 32px;
    margin-bottom: 0;
    padding: 10px;
    line-height: 37px;
    background-color: rgba(0, 0, 0, .8);
}

/* audio image */
.icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.audio-image {
    width: 300px;
    height: 300px;
    background: var(--text);
}

.audio-icon {
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
    border-radius: 50%;

}

.audio-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    vertical-align: middle;
}

/* controls */
.controls-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.controls button,
.volume button {
    border: none;
    background-color: transparent;
    color: var(--text);
    margin-right: 8px;
    cursor: pointer;
}

button svg {
    font-size: 20px;
}

.volume {
    display: flex;
    align-items: center;
}

.volume button {
    margin: 0;
}

.progress {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.progress_bar {
    width: 100%;
}

.author {
    color: var(--text);
}

/* time */
.time {
    color: var(--text);
    font-size: 13px;
    line-height: 46px;
}

.time.current {
    color: var(--text);
    ;
}

input[type="range"] {
    --range-progress: 0;

    --webkit-appearance: none;
    position: relative;
    background: #ccc;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    cursor: pointer;
}

/* Input range - firefox */
input[type="range"]::-moz-range-track {
    position: relative;
    background: #ccc;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    cursor: pointer;
}

/* played progress length - Chrome & safari*/
input[type="range"]::before {
    content: '';
    height: 2px;
    background: #f50;
    width: var(--range-progress);
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    position: absolute;
    top: 0;
    left: 0;
}

/* played progress length - firefox */
input[type="range"]::-moz-range-progress {
    background: #f50;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    height: 2px;
}

/* slider thumb - chrome and safari */
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: none;
    background-color: #f50;
    cursor: pointer;
    position: relative;
}

/* dragging thumb - chrome and safari */
input[type="range"]:active::-webkit-slider-thumb {
    transform: scale(1.2);
}

/* slider thumb - firefox */
input[type="range"]::-moz-range-thumb {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: #f50;
    cursor: pointer;
    border: transparent;
    position: relative;
}

/* dragging thumb - firefox */
input[type="range"]:active::-moz-range-thumb {
    transform: scale(1.2);
}

@media (max-width: 2000px) {}

@media (max-width: 1000px) {}

@media (max-width: 800px) {}

@media (max-width: 400px) {
    .container-audio {
        display: flex;
        flex-direction: column;
    }

    .audio-info {
        display: flex;
        flex-direction: column;
    }

    .audio-image {
        width: auto;
    }

    .title {
        font-size: 25px;
    }

    .author {
        text-align: end;
    }

    .controls-wrapper {
        display: flex;
        flex-direction: column;
    }

    .volume {
        display: none;
    }

}