@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,400;0,700;1,200;1,400&display=swap');

:root {
  --background-color: #2b2655;
  --text: #F3D9FF;
  --accent: #EF2DFB;
}

.light {
  --background-color: #F2FFFF;
  --text: #000000;
  --accent: #34F3FB;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  height: 100%;
  max-width: 100vw;
}

body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  background-color: var(--background-color);
  color: var(--text);
  line-height: 2.2;
}