.nav-hero {
    width: 100vw;
    position: relative;
}

.container-card {
    line-height: 0;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    margin: 40px;
}

.container-card .card {
    width: 100% !important;
    height: auto !important;
    position: relative;
    margin-bottom: 20px;
}

@media (max-width: 2000px) {
    .container-card {
        -moz-column-count: 3;
        -webkit-column-count: 3;
        column-count: 3;
    }
}

@media (max-width: 1000px) {
    .container-card {
        -moz-column-count: 3;
        -webkit-column-count: 3;
        column-count: 3;
    }
}

@media (max-width: 800px) {
    .container-card {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
    }
}

@media (max-width: 400px) {
    .container-card {
        -moz-column-count: 1;
        -webkit-column-count: 1;
        column-count: 1;
    }
}

.card-inner {
    width: inherit;
    height: inherit;
    padding: 10px;
    background: rgba(255, 255, 255, .05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
    border-radius: 8px;
    line-height: normal;
}

.circle {
    width: 50px;
    height: 50px;
    background: radial-gradient(#b0e633, #53ef7d);
    border-radius: 50%;
    position: absolute;
    top: 0;
    animation: move-up6 2s ease-in infinite alternate-reverse;
}

.circle-2 {
    width: 80px;
    height: 80px;
    background: radial-gradient(#f758ff, #EF2DFB);
    border-radius: 50%;
    position: absolute;
    animation: move-up6 2s ease-in infinite alternate-reverse;
}

.circle-3 {
    width: 40px;
    height: 40px;
    background: radial-gradient(#58deff, #2cc5eb);
    border-radius: 50%;
    position: absolute;
    animation: move-up6 2s ease-in infinite alternate-reverse;
}

.circle-4 {
    width: 100px;
    height: 100px;
    background: radial-gradient(#ff5877, #f3748b);
    border-radius: 50%;
    position: absolute;
    animation: move-up6 2s ease-in infinite alternate-reverse;
}

.card .circle-2 {
    right: 0;
}

.card .circle-3 {
    bottom: 0;
    right: 10%;
}

.card .circle-4 {
    bottom: 0;
}

.circle:nth-child(1) {
    top: 0;
    left: -25px;
}

.circle:nth-child(2) {
    bottom: 0;
    right: -5px;
    animation-name: move-down1;
}

@keyframes move-up6 {
    to {
        transform: translateY(-10px);
    }
}

@keyframes move-down1 {
    to {
        transform: translateY(10px);
    }
}