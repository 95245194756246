.container-contact {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-contact {
    position: relative;
    width: 250px;
    height: 400px;
    background: #f4f4f4;
    z-index: 1;
    border-radius: 21px;
    overflow: hidden;
}

.card__content {
    background: linear-gradient(rgba(255, 255, 255, 0.473), rgba(150, 150, 150, 0.25));
    z-index: 1;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(20px);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 21px;
}

.card-contact .blob {
    position: absolute;
    z-index: -1;
    border-radius: 5em;
    width: 200px;
    height: 200px;
}

.card-contact .blob:nth-child(2) {
    left: -60px;
    top: -100px;
    background: #08b2e3;
}

.card-contact .blob:nth-child(3) {
    left: 110px;
    top: -20px;
    z-index: -1;
    background: #ea4769;
}

.card-contact .blob:nth-child(4) {
    left: -40px;
    top: 150px;
    background: #fcde39;
}

.card-contact .blob:nth-child(5) {
    left: 100px;
    top: 200px;
    background: #9b4c97;
}

.card__text {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    transform: translate(-50%, -50%);
    color: var(--text);
    font-size: 20px;
    text-align: center;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.75);
}